
import {
  IonPage,
  IonHeader,
  IonContent,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";

import Header from "@/components/header.vue";
import { onMounted, ref } from "vue";
import { getDetail, setComment } from "@/service/information";
import router from "@/router";
import { openTips, openModal } from "@/utils/alert";
import { userinfo, checklogin } from "@/utils/usermodule";
import { getDate, getDateTime } from "@/utils/moment";

export default {
  name: "InformationDetail",
  components: { Header, IonHeader, IonContent, IonPage },
  setup() {
    const {
      currentRoute: {
        value: {
          query: { id },
        },
      },
    } = router as any;
    const detail: any = ref([]);
    const isComment: any = ref(false);
    const comment: any = ref("");

    const getInformationDetail = async () => {
      const result = await getDetail({
        code: id,
      });
      detail.value = result;
    };

    const toggleComment = () => {
      isComment.value = !isComment.value;
    };

    const submitComment = async () => {
      //检测是否登录
      if (!checklogin()) {
        return;
      }

      if (comment.value === "") {
        openTips("留言内容为空!");
        return;
      }
      if (comment.value.length > 100) {
        openTips("留言内容最多为100字!");
        return;
      }
      const result = await setComment({
        NewId: id,
        FeedContent: comment.value,
      });
      if (result) {
        openModal("留言成功!");
        comment.value = "";
        isComment.value = false;
      }
    };

    onMounted(() => {
      getInformationDetail();
    });

    return {
      detail,
      isComment,
      comment,
      toggleComment,
      submitComment,
      getDateTime,
    };
  },
};
