import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-521c74eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail-header p-base" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "explain justify-space-between mt-base" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "detail-comment p-base" }
const _hoisted_6 = { class: "justify-space-between" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_Header, { title: "资讯详情" })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h1", _hoisted_2, _toDisplayString($setup.detail.newTitle), 1),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", null, _toDisplayString($setup.getDateTime($setup.detail.createTime)), 1),
              _createElementVNode("div", null, "来源：" + _toDisplayString($setup.detail.authorName), 1)
            ])
          ]),
          _createElementVNode("div", {
            class: "p-base",
            innerHTML: $setup.detail.newContent
          }, null, 8, _hoisted_4),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              ($setup.isComment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, "写留言"))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "color-primary",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.toggleComment && $setup.toggleComment(...args)))
              }, _toDisplayString($setup.isComment ? "取消" : "我要留言"), 1)
            ]),
            ($setup.isComment)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _withDirectives(_createElementVNode("textarea", {
                    class: "mt-base p-base",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.comment) = $event)),
                    placeholder: "请输入留言内容!"
                  }, null, 512), [
                    [_vModelText, $setup.comment]
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("button", {
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.submitComment && $setup.submitComment(...args)))
                    }, "留言")
                  ])
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}