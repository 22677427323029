import request from '@/utils/http.service';
import { informationApi } from '@/utils/api.config';

export function getList(data: any) {
  return request({
    url: informationApi.list,
    method: 'POST',
    data
  }).then((results) => results)
}

export function getDetail(data: any) {
  return request({
    url: informationApi.detail,
    method: 'POST',
    data
  }).then((results) => results)
}

export function setComment(data: any) {
  return request({
    url: informationApi.comment,
    method: 'POST',
    data
  }).then(() => true)
}

